//
// components.scss
//

//component-wrapper
.component-wrapper {
    .title {
        font-size: 20px;
    }
}

//Buttons
@each $name,
$value in $colors {
    .btn-#{$name} {
        background-color: #{$value} !important;
        border: 1px solid #{$value} !important;
        color: $white !important;
        box-shadow: 0 3px 5px 0 rgba($value, 0.3);
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: darken($value, 10%) !important;
            border-color: darken($value, 10%) !important;
            color: $white !important;
        }
    }

    .btn-outline-#{$name} {
        border: 1px solid #{$value};
        color: #{$value};
        background-color: transparent;    
        &:hover, &:focus, &:active, &.active, &.focus, &:not(:disabled):not(.disabled):active{
            background-color: #{$value};
            border-color: #{$value};
            color: $white;
            box-shadow: 0 3px 5px 0 rgba($value, 0.3);
        }
    }
}
.btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    font-weight: 600;
    border-radius: 10px;
    &:focus{
        box-shadow: none !important;
    }
    &.btn-sm{
        padding: 7px 16px;
        font-size: 10px;
    }
    &.btn-lg{
        padding: 14px 30px;
        font-size: 16px;
    } 
    &.searchbtn {
        padding: 6px 20px;
    }

    &.btn-primary {
      color: $light !important;
      background: $primary;
      border: 1px solid $gray-300;
      &:hover, &:focus, &:active, &.active, &.focus{
          background-color: darken($light, 10%) !important;
          color: $dark; 
      }
  }

    &.btn-light {
        color: $dark !important;
        background: $light;
        border: 1px solid $gray-300;
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: darken($light, 10%) !important;
            color: $dark; 
        }
    }    
    &.btn-outline-light {
        border: 1px solid $gray-300;
        color: $dark !important;
        background-color: transparent;    
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: $light;
        }
    }
}

button:not(:disabled) {
    outline: none;
}
//Shadow 
.shadow {
    box-shadow: $shadow !important;
}

//Badges
@each $name,
$value in $colors {
    .badge-#{$name} {
        background-color: #{$value} !important;
        color: $white !important;
    }

    .badge-outline-#{$name} {
        background-color: transparent !important;
        color: #{$value} !important;
        border: 1px solid #{$value} !important;
    }
}
.badge {
    padding: 5px 8px;
    border-radius: 3px;
    letter-spacing: 0.5px;
    &.badge-light {
        color: $dark !important;
        background-color: $light !important;
    }

    &.badge-outline-light {
        color: $dark !important;
        border: 1px solid darken($light, 20%) !important;
        background-color: transparent !important;
    }
    &:focus, &.focus {
        box-shadow: none !important;
    }
}

//Alert
@each $name,
$value in $colors {
    .alert-#{$name} {
        background-color: lighten($value, 5%);
        color: $white;
        border-color: $value;
        .alert-link {
            color: darken($value, 30%);
        }
    }
}
.alert {
    padding: 8px 15px;
    border-radius: 10px;
    &.alert-light {
        background-color: $light;
        color: $dark;
        border-color: $gray-300;
    }
    .close {
        top: -5px;
        right: -10px;
    }
}

//Breadcrumb
.page-next-level{
    .title {

      font-family: Passion One;
      font-style: normal;
      font-weight: normal;
      font-size: 72px;
      line-height: 106px;
      text-align: center;
      letter-spacing: 0.23em;

      color: $secondary;
      
      

    }
    .page-next {
        letter-spacing: 0.5px;
        position: relative;
        padding-left: 0;
        top: 70px;
        z-index: 99;
        li {
            display: inline-block;
            margin-right: 5px;
            &:after {
                content: "\f142";
                font-size: 16px;
                color: $dark; 
                font-family: 'Material Design Icons';
            }     
            &:last-child {
                margin-right: 0;
                &:after {
                    display: none;
                }
            }       
            a, span {        
                font-size: 14px;
                padding-right: 8px;           
                transition: all 0.5s ease;
            }
            a {
                &:hover {
                    color: $primary !important;
                }
            }
            span {
                &:last-child {
                    padding-right: 0px;
                }
            }
        }
    }
}

//Avatar
.avatar {
    &.avatar-small{
        height: 80px;
    }
    &.avatar-medium{
        height: 110px;
    }
    &.avatar-large{
        height: 140px;
    }
}
//FAQ
.faq-container {
    .question {
        font-size: 20px;
    }
}

.faq-content {
    .card {
        .faq {           
            .card-header {
                padding-right: 40px !important;
                &.card-header-2 {
                    border-bottom: 0;
                    .faq-question {
                        font-size: 18px;
                    }
                }
            }
        }  
        a.faq{
            background-color: $white;
            color: $primary;
            transition: all 0.5s;
            &.text-primary{
                &:before {
                    content: '\F143';
                    display: block;
                    font-family: 'Material Design Icons';
                    font-size: 18px;
                    color: $dark;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.5s ease;
                }
            }
            &.text-dark {
                background-color: $white;
                color: $dark;
                transition: all 0.5s;
                &:before {
                    content: '\F143';
                    display: block;
                    font-family: 'Material Design Icons';
                    font-size: 18px;
                    color: $dark;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    transform: rotate(180deg);
                    transition: all 0.5s ease;
                }
            }
        }
        .title {
            font-size: 18px;
        }
    }
}

//Nav
.nav-pills {
    .nav-item {
        margin-bottom: 15px;
    }
    .nav-link{
        color: $dark !important;
        margin: auto 15px;
        border: 1px solid $gray-300;
        padding: 5px;
        &.active {
            background-color: $primary;
            color: $white !important;
            border-color: $primary !important;
            box-shadow: $shadow;
            border-radius: 10px !important;
        }
    }
    a {
        .title {
            font-size: 18px;
            font-weight: 700;
        }
    }
}
//Processbar
.progress-box {
    .title {
        font-size: 15px;
    }
    .progress {
        height: 8px;
        overflow: visible;
        .progress-bar {
            border-radius: 10px;
            animation: animate-positive 3s;
        }
        .progress-value {
            position: absolute;
            top: -25px;
            right: -15px;
            font-size: 13px;
        }
    }
    @keyframes animate-positive {
        0% {
            width: 0;
        }
    }
}
//blockquote
.blockquote {
    border-left: 3px solid $gray-300;
    border-radius: 10px;
    font-size: 16px;
}

//Form
.form-group {
    margin-bottom: 20px;
    label {
        font-size: 14px;
        font-weight: 700;
    }
    .form-control {
        box-shadow: none;
        border: 1px solid $gray-300;
        height: 42px; 
        font-size: 13px;
        border-radius: 10px;
        transition: all 0.5s ease;
        &:focus, &.active {
            border-color: $primary;
        }
    } 
    textarea {
        height: 120px !important;
        &.form-control {
            line-height: 25px;
        }
    }
}
.form-control:disabled, .form-control[readonly] {
    background-color: transparent;
    opacity: 1;
}
//Checkbox / Radio button
.custom-control-input:checked~.custom-control-label::before {
    color: $white;
    border-color: $primary;
    background-color: $primary;
}
.custom-control-input:focus~.custom-control-label::before,
.form-control:focus {
    box-shadow: none;
    border-color: $primary;
}

//Subscribe form
.subcribe-form {
    input {
        padding: 14px 20px;
        width: 100%;
        color: $dark !important;
        border: none;
        outline: none !important;
        padding-right: 160px;
        padding-left: 30px;
        background-color: rgba($white, 0.8);
    }
    button {
        position: absolute;
        top: 6px;
        right: 6px;
        outline: none !important;
    }
    form {
        position: relative;
        max-width: 600px;
        margin: 0px auto;
    }
}

//Display
.display-1 {
    font-size: 80px !important;
}
.display-2 {
    font-size: 72px !important;
}
.display-3 {
    font-size: 64px !important;
}
.display-4 {
    font-size: 56px !important;
}

@media (max-width: 768px) {
    .display-1 {
        font-size: 56px !important;
    }
    .display-2 {
        font-size: 50px !important;
    }
    .display-3 {
        font-size: 42px !important;
    }
    .display-4 {
        font-size: 36px !important;
    }
}