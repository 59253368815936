//
// helper.scss
//

.section {
    padding: 100px 0;
    position: relative;
}
.section-two {
    padding: 60px 0;
    position: relative;
}
.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}
.bg-overlay-gradient {
    background-color: $gradident-overlay;
}

.bg-overlay-dark {
    background: darken($primary, 30%);
    opacity: 0.8;
}
//Title
.title-heading {
    line-height: 26px;
    .heading {
      font-family: Passion One;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: 0.1em;

      
      color: #FFFFFF;      
    }
    .heading-cursive {
      font-family: La Belle Aurore;
      font-style: normal;
      font-weight: normal;
      font-size: 72px;
      line-height: 133px;
      letter-spacing: 0.1em;

      color: #FFFFFF;

      text-shadow: 3px 7px 2px rgba(0, 0, 0, 0.75);     
    }
    .para-desc {
        font-size: 18px;
    }
}
.section-title {
    position: relative;
    .main-title {
        font-size: 35px;
        letter-spacing: 0.8px;
    }
    .title {
        letter-spacing: 0.5px;
        font-size: 30px;
    }
} 
.para-desc {
    max-width: 600px;
}
.mt-100 {
    margin-top: 100px;
}

.mb-60 {
    margin-bottom: 60px;
}
//Shapes
.home-shape-bottom {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: -1px;
    left: 0;
    img {
        width: 100%;
        height: auto;
    }
}
.home-shape-arrow {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: -38px;
    z-index: 1;
    left: 0;
    img {
        height: auto;
        transform: rotate(180deg);
    }
    .arrow-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        display: inline-block;
    }
}

.mover {
    animation: mover 1s infinite alternate;
}
@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        transform: translateY(5px);
    }
}

// Loader
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, $white, $white);
    z-index: 9999999;
    #status {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        .spinner {
            width: 40px;
            height: 40px;
            position: relative;
            margin: 100px auto;
            .double-bounce1, .double-bounce2 {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: $primary;
                opacity: 0.6;
                position: absolute;
                top: 0;
                left: 0;
                animation: sk-bounce 2.0s infinite ease-in-out;
            }
            .double-bounce2 {
                animation-delay: -1.0s;
            }
        }
    }
}

@keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
    }
  }

//Social icons
.social-icon {
    li {
        a {
            color: $dark;
            border: 1px solid $dark;
            display: inline-block;
            height: 32px;
            text-align: center;
            font-size: 15px;
            width: 32px;
            line-height: 30px;
            transition: all 0.4s ease;
            overflow: hidden;
            position: relative;
            &:hover {
                background-color: $primary;
                border-color: $primary !important;
                color: $white !important;
            }
        }
    }
    &.social {
        li {
            a {
                color: $gray-500;
                border-color: $gray-500;
            }
        }
    }
}
//back to top
.back-to-top {
    width: 33px !important;
    height: 33px !important;
    line-height: 33px !important;
    color: $dark !important;
    background-color: $gray-400 !important;
    transition: all 0.5s ease !important;
    box-shadow: $shadow !important;
    svg {
        width: 10px !important;
        transition: all 0.5s ease !important;
    }
    &:hover {
        background-color: $primary !important;
        color: $white !important;
        transform: rotate(45deg) !important;
        svg {
            transform: rotate(-45deg) !important;
            fill: $white !important;
        }
    }
}
//back-to-home
.back-to-home {
    position: absolute;
    top: 4%;
    right: 2%;
    z-index: 1;
    a {
        font-size: 22px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: $primary;
    }
}
@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
        .heading {
            font-size: 40px;
        }
    }
}
@media (max-width: 767px) {
    .pt-sm-60 {
        padding-top: 0 !important;
    }
}
@media (max-width: 768px) {
    .section {
        padding: 60px 0;
    }
    .title-heading {
        .heading {
            font-size: 35px !important;
        }
        .para-desc {
            font-size: 18px;
        }
    }
    .section-title {
        .main-title {
            font-size: 28px;
        }
        .title {
            font-size: 24px;
        }
    }
    .mt-60 {
        margin-top: 60px;
    }
}